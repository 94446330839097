import styled from 'styled-components';
import ArrowImg from '../../images/arrow-blue.png';

export const MainDiv = styled.div`
  color: #666;
  font-family: 'Gotham-Book', arial, sans-serif;
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.02em;

  @media (max-width: 767px) {
    font-size: 15px;
    line-height: 22px;
  }
`;
export const HorizontalBreak = styled.hr`
  height: 1px;
  border: none;
  background: #e5e5e5;
  margin: 50px 0;
  clear: both;
  opacity: 1;
  @media (max-width: 767px) {
    margin: 30px 0;
  }
`;
export const HeaderH3 = styled.h3`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  font-size: 28px;
  line-height: 35px;
  color: #008752;
  @media (max-width: 767px) {
    font-size: 18px;
    line-height: 23px;
    margin-bottom: 20px;
  }
`;
export const ParaText = styled.p`
  margin: 0 0 30px 0;
  padding: 0;
  code {
    font-family: 'Gotham-Light', arial, sans-serif;
    font-size: 22px;
    line-height: 31px;
    color: #666;
    @media (max-width: 767px) {
      font-size: 18px;
      line-height: 25px;
    }
  }
  b {
    font-family: 'Gotham-Bold', arial, sans-serif;
    font-weight: bold;
  }
`;
export const ImageStyle = styled.img`
  float: ${(props) => (props?.$rightImage ? 'right' : '')};
  margin: ${(props) =>
    props?.$imageRightMargin ? '0 0 30px 30px' : '0 0 35px 0'};
  border: 0;
  vertical-align: top;
  height: auto;
  width: auto !important;
  @media (max-width: 767px) {
    display: block;
    float: none;
    margin: 0 0 30px 0 !important;
  }
  @media (max-width: 399px) {
    width: 100% !important;
    height: auto !important;
  }
`;

export const HeaderH1 = styled.h1`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  font-size: 45px;
  line-height: 50px;
  color: #083a81;
  @media (max-width: 991px) {
    padding-top: 10px;
  }
  @media (max-width: 767px) {
    font-size: 30px;
    line-height: 35px;
  }
`;
export const HeaderH2 = styled.h2`
  margin: 0 0 35px 0;
  padding: 0;
  font-family: 'Gotham-Light', arial, sans-serif;
  font-weight: normal !important;
  font-size: 35px;
  line-height: 40px;
  color: #0069aa;
  @media (max-width: 767px) {
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 20px;
  }
`;

export const UnorderedList = styled.ul`
  margin: 0 0 30px 0;
  padding: 0;
`;
export const ListItems = styled.li`
  margin: 0;
  padding: 4px 0 4px 25px;
  list-style: none;
  background: url(${ArrowImg}) no-repeat 0 10px;
  background-size: 7px auto;
  p {
    margin: 0;
  }
`;

export const LinkStyle = styled.a`
  cursor: pointer;
  text-decoration: none;
  color: #1fb25a;
  :hover {
    text-decoration: none;
    color: #0397d6;
    outline: none;
  }
`;

export const SpanStyle = styled.span`
  position: relative;
  top: -105px;
  @media (max-width: 991px) {
    top: -80px;
  }
  @media (max-width: 767px) {
    top: -70px;
  }
`;
export const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  padding-bottom: 56%;
  margin: 40px 0 40px 0;
`;

export const Iframe = styled.iframe`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 1px 3px #969696;
`;
